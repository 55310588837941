import {
  WrapKlarna,
  LoadingSpinner,
  ContentContainer,
} from '../../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../components/App'
import { SignicatBranding } from '../../components/SignicatBranding/SignicatBranding'
import { rootStore } from '../../store/Root.store'
import { KlarnaSignicatBranding } from '../../styles/generic.styles'
import { axiosInstance } from '../../methods/axiosConfig'
import { devLog } from '../../methods/devLog'
import { forceRedirect } from '../../methods/forceRedirect'
import { useInterval } from 'usehooks-ts'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AisKlarnaPage = observer(({ klarnaRef }: { klarnaRef: any }) => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const [loading, setLoading] = useState(true)
  const [clientToken, setClientToken] = useState('')
  const [klarnaSDK, setKlarnaSdk] = useState(null)
  const [loadingKlarna, setLoadingKlarna] = useState(true)

  const startSession = async (): Promise<void> => {
    try {
      const res = await axiosInstance.post(
        `${process.env.WEB_API_URL}/klarna-kosma/start-session`,
        {},
        { withCredentials: true }
      )
      setClientToken(res.data.clientToken)
      setLoading(false)
      store.AppState.setIframeContent(true)
    } catch (e) {
      devLog(e)
    }
  }

  const startKlarnaOpenBankingXS2AApp = () => {
    try {
      klarnaSDK.startFlow(`${clientToken}`, {
        skipInitialLoader: true,
        autoClose: true,
        hideTransitionOnFlowEnd: true,
        onFinished: () => {
          setLoading(true)
          store.AppState.setIframeContent(false)
          forceRedirect(
            `${process.env.WEB_API_URL}/klarna-kosma/redirect-from-session?status=complete`
          )
        },
        onError: (error) => {
          setLoading(true)
          store.AppState.setIframeContent(false)
          forceRedirect(
            `${process.env.WEB_API_URL}/klarna-kosma/redirect-from-session?status=error`
          )
        },
        onAbort: () => {
          setLoading(true)
          store.AppState.setIframeContent(false)
          forceRedirect(
            `${process.env.WEB_API_URL}/klarna-kosma/redirect-from-session?status=abort`
          )
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (klarnaSDK && klarnaSDK.startFlow) {
      startSession()
    }
  }, [klarnaSDK])

  const updateKlarnaSDK = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tempKlarnaSDK = (window as any).XS2A
    if (tempKlarnaSDK.startFlow) {
      setKlarnaSdk(tempKlarnaSDK)
      setLoadingKlarna(false)
    }
  }

  useEffect(() => {
    if (clientToken.length > 0) {
      startKlarnaOpenBankingXS2AApp()
    }
  }, [clientToken])

  useInterval(updateKlarnaSDK, loadingKlarna ? 500 : null)

  return (
    <>
      {loading ? (
        <>
          <ContentContainer
            {...theme.container}
            width="560px"
            paddingMobile="20px"
            marginMobile="0"
          >
            <LoadingSpinner
              width="68px"
              padding="100px 0"
              {...theme.loadingSpinner}
            />
          </ContentContainer>
          <SignicatBranding width="560px" hideOnMobile />
        </>
      ) : (
        <WrapKlarna {...theme.wrapKlarna}>
          <KlarnaSignicatBranding>
            <SignicatBranding width="132px" />
          </KlarnaSignicatBranding>
        </WrapKlarna>
      )}
    </>
  )
})
